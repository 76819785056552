import api from "@/api/api";

export default {
  data: () => ({
    token: null,
  }),
  methods: {
    async getNftTokenByBlockChainId() {
      try {
        this.token = await api.getNftTokenByBlockChainId({
          blockChainId: this.id,
          currency: this.tokenCurrency.toUpperCase(),
        });
      } catch {
        this.setLoading(false);
        await this.$router.push("/404");
      }
    },

    async getNftTokenByInnerId() {
      try {
        this.token = await api.getNftTokenByInnerId({
          innerId: this.id,
        });
      } catch {
        this.setLoading(false);
        await this.$router.push("/404");
      }
    },
  },
};
