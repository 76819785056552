<template>
  <div class="fel-item" :class="{ active: collection.active }" @click="click">
    <div class="fel-image">
      <img :src="collectionImageUrl" :alt="collectionTitle" />
    </div>
    <div class="fel-overlay">
      <div class="fel-title">{{ truncate(collectionTitle, 25) }}</div>
    </div>
  </div>
</template>

<script>
import collectionInfoMixin from "@/mixins/collectionInfoMixin";
import truncateMixin from "@/mixins/truncateMixin";

export default {
  name: "PreCreateCollectionCard",
  mixins: [collectionInfoMixin, truncateMixin],
  props: {
    collection: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    click() {
      this.$emit("click", {
        collectionId: this.collectionId,
        index: this.index,
      });
    },
  },
};
</script>
