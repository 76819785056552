import errors from "@/util/errors";

export default {
  data: () => ({
    titleError: null,
    descriptionError: null,
  }),
  computed: {
    titleComputed: {
      get() {
        return this.title;
      },
      set(val) {
        this.titleError = null;
        if (!val) {
          this.titleError = this.$t(errors.REQUIRED_FIELD);
        }
        this.title = val;
      },
    },
    descriptionComputed: {
      get() {
        return this.description;
      },
      set(val) {
        this.descriptionError = null;
        if (!val) {
          this.descriptionError = this.$t(errors.REQUIRED_FIELD);
        }
        if (val && val.length > 500) {
          this.descriptionError = `${this.$t(errors.MAX_LENGTH_ERROR)} 500`;
        }
        this.description = val;
      },
    },
  },
};
