<template>
  <div id="create-collection" class="popup">
    <div class="popup-overlay" @click="close"></div>

    <div class="popup-container">
      <div class="svg close" @click="close">
        <svg><use xlink:href="#svg-close" /></svg>
      </div>

      <!-- Step 1 -->
      <div class="popup-content" v-show="step === 1">
        <h3 class="center">Create collection</h3>

        <div class="steps">
          <div class="active"></div>
          <div></div>
        </div>

        <div class="form">
          <div class="fel">
            <input type="text" placeholder="Title" v-model="titleComputed" />
            <div class="fel-error">{{ titleError }}</div>
          </div>

          <div class="fel">
            <textarea placeholder="Description" v-model="descriptionComputed" />
            <div class="fel-error">{{ descriptionError }}</div>
          </div>

          <button
            type="button"
            class="button green"
            @click="makeStep('next')"
            :disabled="titleError || descriptionError"
          >Next</button>
        </div>
      </div>
      <!-- Step 1 -->

      <!-- Step 2 -->
      <div class="popup-content" v-show="step === 2">
        <h3 class="center">Create collection</h3>

        <div class="steps">
          <div class="svg done">
            <svg><use xlink:href="#svg-done" /></svg>
          </div>
          <div class="active"></div>
        </div>

        <div class="form">
          <div class="fel">
            <div class="fel-label center">Upload the collection cover</div>
            <label class="fel-file">
              <input type="file" ref="file" @change="uploadFile" />

              <img v-if="secureUrl" :src="secureUrl" />
              <div class="svg" v-else>
                <svg><use xlink:href="#svg-upload" /></svg>
              </div>

              <div class="fel-title">Choose a&nbsp;file</div>
              <div class="fel-description">AVIF, JPG, PNG, SVG, WEBP. Max&nbsp;30MB.</div>
              <div class="fel-description">Recommended minimum image size requirements: 1920&nbsp;×&nbsp;520</div>
            </label>
          </div>

          <div class="buttons">
            <button type="button" class="button" @click="makeStep('prev')">Prev</button>
            <button type="button" class="button green" @click="createCollection">Create collection</button>
          </div>
        </div>
      </div>
      <!-- Step 2 -->
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import closeModalMixin from "@/mixins/closeModalMixin";
import UploadCollectionImageMixin from "@/mixins/collection/uploadCollectionImageMixin";
import errors from "@/util/errors";
import successes from "@/util/successes";

export default {
  name: "CreateCollectionModal",
  mixins: [UploadCollectionImageMixin, closeModalMixin],
  data: () => ({
    step: 1,
    title: null,
    description: null,
    titleError: null,
    descriptionError: null,
  }),
  computed: {
    titleComputed: {
      get() {
        return this.title;
      },
      set(val) {
        this.titleError = null;
        if (!val) {
          this.titleError = this.$t(errors.REQUIRED_FIELD);
        }
        this.title = val;
      },
    },
    descriptionComputed: {
      get() {
        return this.description;
      },
      set(val) {
        this.descriptionError = null;
        if (!val) {
          this.descriptionError = this.$t(errors.REQUIRED_FIELD);
        }
        if (val && val.length > 500) {
          this.descriptionError = `${this.$t(errors.MAX_LENGTH_ERROR)} 500`;
        }
        this.description = val;
      },
    },
  },
  methods: {
    validate() {
      if (this.step === 1) {
        if (!this.title) {
          this.titleError = this.$t(errors.REQUIRED_FIELD);
          return false;
        }
        if (!this.description) {
          this.descriptionError = this.$t(errors.REQUIRED_FIELD);
          return false;
        }
      }
      if (this.step === 2) {
        if (!this.secureUrl) {
          this.setError(errors.NEED_FILE_UPLOAD);
          return false;
        }
      }
      return true;
    },
    makeStep(step) {
      switch (step) {
        case "prev":
          this.step--;
          break;
        case "next":
          if (!this.validate()) {
            return;
          }
          this.step++;
          break;
      }
    },
    async createCollection() {
      try {
        if (!this.validate()) {
          return;
        }
        this.setLoading(true);
        await api.createNftCollection({
          description: this.description,
          fileId: this.publicId,
          fileUrl: this.secureUrl,
          title: this.title,
        });
        this.setSuccess(successes.COLLECTION_CREATED);
        this.$emit("success");
        this.close();
      } catch (e) {
        this.setError(e.response.data.code);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
