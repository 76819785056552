export default {
  COLLECTION_REMOVED: "f-s-1",
  TOKEN_REMOVED_FROM_COLLECTION: "f-s-2",
  TOKEN_COLLECTION_CHANGED: "f-s-3",
  TOKEN_ADDED_TO_COLLECTION: "f-s-4",
  TRANSACTION_REQUEST_SENT: "f-s-5",
  AUCTION_TIME_FRAME_CHANGED: "f-s-6",
  AUCTION_PRICE_CHANGED: "f-s-7",
  AUCTION_CLOSED: "f-s-8",
  SUCCESS_PURCHASE: "f-s-9",
  BID_PLACED: "f-s-10",
  COLLECTION_CREATED: "f-s-11",
  UP_FOR_SALE: "f-s-12",
  REPORT_SUBMITTED: "f-s-13",
  NFT_MINTED: "f-s-14",
  NFT_UPDATED: "f-s-15",
  TOKEN_BURNED: "f-s-16",
};
