import cloudinary from "@/util/cloudinary";
import http from "@/util/http-common";
import SetProcessingMixin from "@/mixins/setProcessingMixin";

export default {
  mixins: [SetProcessingMixin],
  data: () => ({
    secureUrl: null,
    publicId: null,
  }),
  methods: {
    async uploadFile() {
      try {
        this.setLoading(true);
        let file = this.$refs.file.files[0];
        const formData = new FormData();
        formData.append("upload_preset", cloudinary.preset);
        formData.append("file", file);
        formData.append("folder", "Collections");
        const response = await http.post(
          cloudinary.getCloudinaryUploadUrl("image"),
          formData,
          {
            params: {
              isPublic: true,
            },
          }
        );
        this.secureUrl = response.data.secure_url;
        this.publicId = response.data.public_id;
      } catch (e) {
        this.setError(e.response.data.code || e.response.data.error.message);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
