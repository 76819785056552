import api from "@/api/api";
import successes from "@/util/successes";
import SetProcessingMixin from "@/mixins/setProcessingMixin";

export default {
  mixins: [SetProcessingMixin],
  methods: {
    async removeTokenFromCollection(
      tokenId,
      collectionId,
      showSuccess = true,
      filterList = true,
      throwError = false
    ) {
      try {
        this.setLoading(true);
        await api.removeTokenFromCollection({
          tokenId,
          collectionId,
        });
        if (showSuccess) {
          this.setSuccess(successes.TOKEN_REMOVED_FROM_COLLECTION);
        }
        if (filterList) {
          this.items = this.items.filter((i) => i.id !== tokenId);
        }
      } catch (e) {
        this.setError(e.response.data.code);
        if (throwError) throw e;
      } finally {
        this.setLoading(false);
      }
    },
  },
};
