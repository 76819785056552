<template>
  <div v-if="token">
    <CreateCollectionModal
      v-if="showCollectionModal"
      @success="collectionCreated"
      @close="showCollectionModal = false"
    />

    <div id="intro">
      <div class="section">
        <h1>Edit NFT</h1>
      </div>
    </div>

    <div id="create-token" class="section">
      <form class="flex">
        <div class="half">
          <div class="fel">
            <div class="fel-label">Name of token</div>
            <input type="text" name="name" v-model="titleComputed" />
            <div class="fel-error">{{ titleError }}</div>
          </div>

          <div class="fel">
            <div class="fel-label">Description</div>
            <textarea name="description" v-model="descriptionComputed" />
            <div class="fel-error">{{ descriptionError }}</div>
          </div>

          <div class="fel">
            <div class="fel-label">Collections</div>
            <div class="fel-collections">
              <div class="fel-item" @click="showCollectionModal = true">
                <div class="svg">
                  <svg><use xlink:href="#svg-add" /></svg>
                </div>
              </div>

              <PreCreateCollectionCard
                v-for="(item, index) in items"
                :key="index"
                :collection="item"
                :index="index"
                @click="selectCollection"
              />
            </div>

            <ScrollLoader
              :loader-method="getUserNftCollections"
              :loader-disable="disableLoading"
              :loader-distance="300"
              ><img width="200" height="200" src="@/assets/img/preloader.svg" />
            </ScrollLoader>
          </div>

          <div class="fel">
            <div class="fel-label">Tags</div>
            <div class="fel-tags">
              <div class="fel-wrapper">
                <div class="flex">
                  <div
                    class="tag"
                    v-for="(item, index) in tokenTags"
                    :key="index"
                  >
                    <span>#{{ item }}</span>
                    <span class="svg" @click="deleteTag(index)">
                      <svg><use xlink:href="#svg-close" /></svg>
                    </span>
                  </div>
                </div>
              </div>
              <div class="fel-tools">
                <input
                  type="text"
                  placeholder="#"
                  v-model.trim="tag"
                  @keydown.enter.prevent
                  @keyup.enter.prevent="addTag"
                  @keydown.space="(event) => event.preventDefault()"
                />
                <div class="button round" @click="addTag">add</div>
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              type="button"
              class="green"
              :disabled="titleError || descriptionError"
              @click="updateTokenData"
            >
              Save
            </button>

            <button type="button">Cancel</button>
          </div>
        </div>

        <div class="half">
          <div class="fel-label">Preview</div>

          <TokenCard
            v-if="getSymbol"
            :disable-control="true"
            :token="{
              activeAuction: null,
              title: tokenTitle,
              fileUrl: tokenFileUrl,
              previewUrl: tokenPreviewUrl,
              type: tokenType,
              owner: {
                id: getId,
                address: getAddress,
                nickname: getNickName,
              },
              currency: getSymbol,
            }"
          />
        </div>
      </form>
    </div>
    <!--[ #create-token ]-->
  </div>
</template>

<script>
import setProcessingMixin from "@/mixins/setProcessingMixin";
import api from "@/api/api";
import CreateCollectionModal from "@/components/modals/CreateCollectionModal";
import PreCreateCollectionCard from "@/components/common/PreCreateCollectionCard";
import { mapGetters } from "vuex";
import TokenCard from "@/components/common/TokenCard";
import getUserNFTCollectionsMixin from "@/mixins/collection/getUserNFTCollectionsMixin";
import getTokenByBlockChainIdMixin from "../mixins/token/GetTokenByBlockChainIdMixin";
import tokenInfoMixin from "../mixins/tokenInfoMixin";
import tokenInfoValidationMixin from "../mixins/token/TokenInfoValidationMixin";
import successes from "../util/successes";
import removeTokenFromCollectionMixin from "@/mixins/collection/removeTokenFromCollectionMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "TokenEdit",
  props: {
    id: {
      type: String,
      required: true,
    },
    contract: {
      type: String,
      required: false,
    },
    tokenCurrency: {
      type: String,
      required: true,
    },
  },
  mixins: [
    setProcessingMixin,
    getUserNFTCollectionsMixin,
    getTokenByBlockChainIdMixin,
    tokenInfoMixin,
    tokenInfoValidationMixin,
    useLinkMixin,
    removeTokenFromCollectionMixin,
  ],
  data: () => ({
    showCollectionModal: false,
    collectionIds: [],
    tag: "",
    title: null,
    description: null,
    titleError: null,
    descriptionError: null,
  }),
  computed: {
    ...mapGetters({
      getSymbol: "wallet/getSymbol",
      getAddress: "user/getAddress",
      getNickName: "user/getNickName",
      getId: "user/getId",
      getMethodsInitialized: "wallet/getMethodsInitialized",
      getContract: "wallet/getContract",
    }),
  },
  methods: {
    async updateTokenData() {
      try {
        const body = {
          description: this.description,
          fileId: null,
          fileUrl: null,
          ownerPercent: null,
          previewId: null,
          previewUrl: null,
          title: this.title,
          type: null,
        };
        const query = {
          tokenId: this.tokenId,
        };
        this.setLoading(true);
        await api.updateTokenData({ body, query });
        this.setSuccess(successes.NFT_UPDATED);
        this.setLoading(false);
        await this.$router.push({
          path: this.nftLink(
            this.tokenBlockChainId || this.tokenId,
            this.tokenCurrency,
            this.getContract
          ),
        });
      } catch (e) {
        this.setError(e.response.data.code);
      } finally {
        this.setLoading(false);
      }
    },
    async deleteTag(index) {
      try {
        await api.deleteTagFromToken({
          tagName: this.tokenTags[index],
          tokenId: this.tokenId,
        });
        this.tokenTags.splice(index, 1);
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    async addTag() {
      try {
        if (!this.tag || this.tokenTags.includes(this.tag)) {
          this.tag = "";
          return;
        }
        this.tag = this.tag.replaceAll("#", "");
        await api.addTagToToken({
          tagName: this.tag,
          tokenId: this.tokenId,
        });
        this.tokenTags.push(this.tag);
        this.tag = "";
      } catch (e) {
        this.setError(e.response.data.code);
      }
    },
    async selectCollection(val) {
      if (this.collectionIds.includes(val.collectionId)) {
        await this.removeTokenFromCollection(
          this.tokenId,
          val.collectionId,
          false
        );
        this.items[val.index].active = false;
        this.collectionIds = this.collectionIds.filter(
          (i) => i !== val.collectionId
        );
        return;
      }
      try {
        await api.addNFTTokenToCollection({
          collectionId: val.collectionId,
          tokenId: this.tokenId,
        });
        this.collectionIds.push(val.collectionId);
        this.items[val.index].active = true;
      } catch (e) {
        this.setError(e?.response?.data?.code);
      }
    },
    collectionCreated() {
      this.page = 0;
      this.disableLoading = false;
      this.items = [];
    },
    async init() {
      this.setLoading(true);
      if (this.contract) {
        await this.getNftTokenByBlockChainId();
      } else {
        await this.getNftTokenByInnerId();
      }
      this.title = this.tokenTitle;
      this.description = this.tokenDescription;
      this.setLoading(false);
    },
  },
  async mounted() {
    await this.init();
  },
  components: {
    TokenCard,
    PreCreateCollectionCard,
    CreateCollectionModal,
  },
};
</script>
