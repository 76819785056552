import { mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations({
      setSuccess: "general/setSuccess",
      setError: "general/setError",
      setNeutral: "general/setNeutral",
      setLoading: "general/setLoading",
      setNotice: "general/setNotice",
    }),
  },
};
